@import '~boxicons';
@import 'variables';

@import 'general';
@import 'page-header';
@import 'header';
@import 'nav';
@import 'hero';
@import 'sections';
@import 'footer';

@import "~bootstrap/scss/bootstrap";
// import Swiper styles
@import '~swiper/swiper-bundle.css';

@import "~leaflet";

.leaflet-container {
  background-color: #CAD2D3;
}

.collapse {
  display: block;
  max-height: 0;
  overflow: hidden;
  transition: max-height .8s cubic-bezier(0, 1, 0, 1);;

  &.show {
    max-height: 99em;
    transition: max-height .8s ease-in-out;
  }
}

.block {
  background: #f5f5f5;
  padding: 0;
  // height: 250px;

  &__content {
    border: 1px solid #ccc;
    padding: 1.5em;
    height: 100%;
  }
}

.map-section {
  min-height: 400px;

  .map-container {
    display: flex;
    justify-content: center;
    align-items: center;

  }
}

#popper {
  visibility: hidden;
  padding: 1em;
  background: white;
  border-radius: 2px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, .3);
  position: absolute;
}