@import 'variables';

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  //background-color: lighten($primary, 49);
  background-color: lighten($primary, 75);
}

.section-title {
  text-align: center;
  padding-bottom: 30px;

  h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0;
    background: lighten($primary, 46);
    color: $primary;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
  }

  h3 {
    margin: 15px 0 0 0;
    font-size: 32px;
    font-weight: 700;

    span {
      color: $primary;
    }
  }

  p {
    margin: 15px auto 0 auto;
    font-weight: 600;
  }

  @media (min-width: 1024px) {
    p {
      width: 50%;
    }
  }

}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0;
  background-color: lighten($primary, 48);
  min-height: 40px;

  h2 {
    font-size: 24px;
    font-weight: 300;
    margin: 0;
    @media (max-width: 992px) {
      margin: 0 0 10px 0;
    }
  }

  ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;

    li + li {
      padding-left: 10px;
    }

    li + li::before {
      display: inline-block;
      padding-right: 10px;
      color: #6c757d;
      content: "/";
    }
  }

  @media (max-width: 768px) {
    .d-flex {
      display: block !important;

    }

    ol {
      display: block;

      li {
        display: inline-block;
      }

    }
  }
}


/*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/
.featured-services {

  .icon-box {
    width: 100%;
    padding: 30px;
    position: relative;
    overflow: hidden;
    background: $white;
    box-shadow: 0 0 29px 0 rgba(68, 88, 144, .12);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
  }

  .icon-box::before {
    content: '';
    position: absolute;
    background: lighten($primary, 40);
    right: 0;
    left: 0;
    bottom: 0;
    top: 100%;
    transition: all 0.3s;
    z-index: -1;
  }

  .icon-box:hover::before {
    background: $primary;
    top: 0;
    border-radius: 0px;
  }

  .icon {
    margin-bottom: 15px;

    i {
      font-size: 48px;
      line-height: 1;
      color: $primary;
      transition: all 0.3s ease-in-out;
    }

  }

  .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;

    a {
      color: #111;
    }
  }

  .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }

  .icon-box:hover {
    .title a, .description {
      color: $white;
    }

    .icon {
      i {
        color: $white;
      }
    }
  }

}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  .content {
    h3 {
      font-weight: 600;
      font-size: 26px;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 35px;
      }

      li:first-child {
        margin-top: 35px;
      }

      i {
        background: #fff;
        box-shadow: 0px 6px 15px rgba($primary, 0.12);
        font-size: 24px;
        padding: 20px;
        margin-right: 15px;
        color: $primary;
        border-radius: 50px;
      }

      h5 {
        font-size: 18px;
        color: lighten($secondary, 20);
      }

      p {
        font-size: 15px;
      }

    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills {

  .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
  }

  .progress .skill {
    padding: 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: $font-secondary;
    color: $secondary;
  }

  .progress .skill .val {
    float: right;
    font-style: normal;
  }

  .progress-bar-wrap {
    background: lighten($primary, 45);
    height: 10px;
  }

  .progress-bar {
    width: 1px;
    height: 10px;
    transition: .9s;
    background-color: $primary;
  }
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 70px 0 60px;

  .count-box {
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: lighten($primary, 48);

    i {
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 24px;
      background: $primary;
      color: $white;
      width: 56px;
      height: 56px;
      line-height: 0;
      border-radius: 50px;
      border: 5px solid #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    span {
      font-size: 36px;
      display: block;
      font-weight: 600;
      color: darken($primary, 30);
    }

    p {
      padding: 0;
      margin: 0;
      font-family: $font-primary;
      font-size: 14px;
    }
  }

}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 15px 0;
  text-align: center;

  img {
    max-width: 45%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 15px 0;

    &:hover {
      transform: scale(1.15);
    }

    @media (max-width: 768px) {
      max-width: 40%;
    }
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {

  .icon-box {
    text-align: center;
    border: 1px solid lighten($primary, 45);
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
    background: $white;

    .icon {
      margin: 0 auto;
      width: 64px;
      height: 64px;
      background: lighten($primary, 48);
      border-radius: 4px;
      border: 1px solid lighten($primary, 44);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      transition: ease-in-out 0.3s;

      i {
        color: lighten($primary, 10);
        font-size: 28px;
        transition: ease-in-out 0.3s;
      }

    }

    h4 {
      font-weight: 700;
      margin-bottom: 15px;
      font-size: 24px;

      a {
        color: $secondary;
        transition: ease-in-out 0.3s;
      }

    }

    p {
      line-height: 24px;
      font-size: 14px;
      margin-bottom: 0;
    }

    &:hover {
      border-color: $white;
      box-shadow: 0px 0 25px 0 rgba($primary, 0.10);

      h4 a, .icon i {
        color: $primary;
      }

      .icon {
        border-color: $primary;
      }
    }
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  background: url("../images/testimonials-bg.jpg") no-repeat center center;
  background-size: cover;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(darken($secondary, 20), 0.7);
  }

  .section-header {
    margin-bottom: 40px;
  }

  .testimonial-item {
    text-align: center;
    color: $white;

    .testimonial-img {
      width: 100px;
      border-radius: 50%;
      border: 6px solid rgba($white, .15);
      margin: 0 auto;
    }

    h3 {
      font-size: 20px;
      font-weight: bold;
      margin: 10px 0 5px 0;
      color: $white;
    }

    h4 {
      font-size: 14px;
      color: #ddd;
      margin: 0 0 15px 0;
    }

    .quote-icon-left, .quote-icon-right {
      color: rgba($white, .4);
      font-size: 26px;
    }

    .quote-icon-left {
      display: inline-block;
      left: -5px;
      position: relative;
    }

    .quote-icon-right {
      display: inline-block;
      right: -5px;
      position: relative;
      top: 10px;
    }

    p {
      font-style: italic;
      margin: 0 auto 15px auto;
      color: #eee;
    }

  }

  .swiper-pagination {
    margin-top: 20px;
    position: relative;

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background-color: rgba(#fff, .5);
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: $primary;
    }
  }

  @media (min-width: 992px) {
    .testimonial-item p {
      width: 80%;
    }
  }

}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio {

  #portfolio-flters {
    padding: 0;
    margin: 0 auto 15px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
    padding: 2px 15px;

    li {
      cursor: pointer;
      display: inline-block;
      padding: 10px 15px 8px 15px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
      color: $default;
      margin-bottom: 5px;
      transition: all 0.3s ease-in-out;

      &:hover, &.filter-active {
        color: $primary;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .portfolio-item {
    margin-bottom: 30px;

    .portfolio-info {
      opacity: 0;
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 0;
      z-index: 3;
      transition: all ease-in-out 0.3s;
      background: rgba($white, .9);
      padding: 15px;

      h4 {
        font-size: 18px;
        color: $white;
        font-weight: 600;
        color: $secondary;
      }

      p {
        color: lighten($secondary, 20);
        font-size: 14px;
        margin-bottom: 0;
      }

      .preview-link, .details-link {
        position: absolute;
        right: 40px;
        font-size: 24px;
        top: calc(50% - 18px);
        color: lighten($secondary, 10);

        &:hover {
          color: $primary;
        }
      }

      .details-link {
        right: 10px;
      }

    }

    .portfolio-links {
      opacity: 0;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 3;
      position: absolute;
      transition: all ease-in-out 0.3s;

      a {
        color: $white;
        margin: 0 2px;
        font-size: 28px;
        display: inline-block;
        transition: 0.3s;

        &:hover {
          color: lighten($primary, 20);
        }
      }

    }

    &:hover {
      .portfolio-info {
        opacity: 1;
        bottom: 20px;
      }
    }
  }
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {

  padding-top: 40px;

  .portfolio-details-slider {
    img {
      width: 100%;
    }

    .swiper-pagination {
      margin-top: 20px;
      position: relative;

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background-color: #fff;
        opacity: 1;
        border: 1px solid $primary;
      }

      .swiper-pagination-bullet-active {
        background-color: $primary;
      }
    }

  }

  .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba($secondary, 0.08);

    h3 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eee;
    }

    ul {
      list-style: none;
      padding: 0;
      font-size: 15px;

      li + li {
        margin-top: 10px;
      }
    }

  }

  .portfolio-description {
    padding-top: 30px;

    h2 {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    p {
      padding: 0;
    }
  }

}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  padding: 60px 0;

  .member {
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 4px;
    background: $white;
    box-shadow: 0px 2px 15px rgba($primary, 0.15);

    .member-img {
      position: relative;
      overflow: hidden;
    }

    .social {
      position: absolute;
      left: 0;
      bottom: 30px;
      right: 0;
      opacity: 0;
      transition: ease-in-out 0.3s;
      text-align: center;

      a {
        transition: color 0.3s;
        color: $secondary;
        margin: 0 3px;
        padding-top: 7px;
        border-radius: 4px;
        width: 36px;
        height: 36px;
        background: rgba($primary, .8);
        display: inline-block;
        transition: ease-in-out 0.3s;
        color: $white;

        &:hover {
          background: lighten($primary, 10);
        }

      }

      i {
        font-size: 18px;
      }
    }

    .member-info {
      padding: 25px 15px;

      h4 {
        font-weight: 700;
        margin-bottom: 5px;
        font-size: 18px;
        color: $secondary;
      }

      span {
        display: block;
        font-size: 13px;
        font-weight: 400;
        color: lighten($default, 40);
      }

      p {
        font-style: italic;
        font-size: 14px;
        line-height: 26px;
        color: lighten($default, 20);
      }

    }

    &:hover {

      .social {
        opacity: 1;
        bottom: 15px;
      }
    }

  }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing {

  .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    overflow: hidden;

    h3 {
      font-weight: 400;
      margin: -20px -20px 20px -20px;
      padding: 20px 15px;
      font-size: 16px;
      font-weight: 600;
      color: lighten($default, 20);
      background: #f8f8f8;
    }

    h4 {
      font-size: 36px;
      color: $primary;
      font-weight: 600;
      font-family: $font-secondary;
      margin-bottom: 20px;

      sup {
        font-size: 20px;
        top: -15px;
        left: -3px;
      }

      span {
        color: #bababa;
        font-size: 16px;
        font-weight: 300;
      }
    }

    ul {
      padding: 0;
      list-style: none;
      color: $default;
      text-align: center;
      line-height: 20px;
      font-size: 14px;

      li {
        padding-bottom: 16px;
      }

      i {
        color: $primary;
        font-size: 18px;
        padding-right: 4px;
      }

      .na {
        color: #ccc;
        text-decoration: line-through;
      }
    }
  }

  .btn-wrap {
    margin: 20px -20px -20px -20px;
    padding: 20px 15px;
    background: #f8f8f8;
    text-align: center;
  }

  .btn-buy {
    background: $primary;
    display: inline-block;
    padding: 8px 35px 10px 35px;
    border-radius: 4px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: $font-primary;
    font-weight: 600;
    transition: 0.3s;

    &:hover {
      background: lighten($primary, 10);
    }
  }

  .featured {
    h3 {
      color: $white;
      background: $primary;
    }
  }

  .advanced {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: $primary;
    color: $white;
  }

}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq {
  padding: 60px 0;

  .faq-list {
    padding: 0;
    list-style: none;

    li {
      border-bottom: 1px solid lighten($primary, 42);
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    .question {
      display: block;
      position: relative;
      font-family: $primary;
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      padding-left: 25px;
      cursor: pointer;
      color: darken($primary, 10);
      transition: 0.3s;
    }

    i {
      font-size: 16px;
      position: absolute;
      left: 0;
      top: -2px;
    }

    p {
      margin-bottom: 0;
      padding: 10px 0 0 25px;
    }

    .icon-show {
      display: none;
    }


    .collapsed {
      color: black;

      &:hover {
        color: $primary;
      }

      .icon-show {
        display: inline-block;
        transition: 0.6s;
      }

      .icon-close {
        display: none;
        transition: 0.6s;
      }
    }
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {

  .info-box {
    color: $default;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
    padding: 20px 0 30px 0;

    i {
      font-size: 32px;
      color: $primary;
      border-radius: 50%;
      padding: 8px;
      border: 2px dotted lighten($primary, 35);
    }

    h3 {
      font-size: 20px;
      color: lighten($default, 20);
      font-weight: 700;
      margin: 10px 0;
    }

    p {
      padding: 0;
      line-height: 24px;
      font-size: 14px;
      margin-bottom: 0;
    }

  }

  .php-email-form {

    box-shadow: 0 0 30px rgba(214, 215, 216, 0.4);
    padding: 30px;

    .error-message {
      display: none;
      color: #fff;
      background: #ed3c0d;
      text-align: left;
      padding: 15px;
      font-weight: 600;

      & br + br {
        margin-top: 25px;
      }
    }

    .sent-message {
      display: none;
      color: #fff;
      background: #18d26e;
      text-align: center;
      padding: 15px;
      font-weight: 600;
    }

    .loading {
      display: none;
      background: #fff;
      text-align: center;
      padding: 15px;

      &:before {
        content: "";
        display: inline-block;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin: 0 10px -6px 0;
        border: 3px solid #18d26e;
        border-top-color: #eee;
        animation: animate-loading 1s linear infinite;
      }

    }

    .form-group {
      margin-bottom: 20px;
    }

    input, textarea {
      border-radius: 0;
      box-shadow: none;
      font-size: 14px;

      &:focus {
        border-color: $primary;
      }
    }

    input {
      padding: 10px 15px;
    }

    textarea {
      padding: 12px 15px;
    }

    button[type="submit"] {
      background: $primary;
      border: 0;
      padding: 10px 30px;
      color: #fff;
      transition: 0.4s;
      border-radius: 4px;

      &:hover {
        background: lighten($primary, 10%);
      }
    }

  }

  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

