@import 'variables';
.page-header-bg {
  //background-color: lighten($primary, 49);
  background-color: lighten($primary, 75);
}

.page-header {
  height: 540px;
  position: relative;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(darken($secondary, 20), 0.5);
  }
}