@import 'variables';

.btn-get-started {
  font-family: $font-primary;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: $white;
  background: $primary;
  &:hover {
    background: lighten($primary, 5);
  }
}
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.three-thirds-height {
  height: 75vh;
}
#hero {
  width: 100%;
  background: url("../images/pxfuel.com.jpg") top left;
  background-size: cover;
  position: relative;

  &:before {
    content: "";
    background: rgba($white, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  .container {
    position: relative;
  }

  h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: $secondary;
    font-family: $font-secondary;
    span {
      color: $primary;
    }
  }

  h2 {
    //color: lighten($secondary, 20);
    color: $primary;
    margin: 5px 0 30px 0;
    font-size: 24px;
    font-weight: 400;
  }

  .btn-watch-video {
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    color: $secondary;
    font-weight: 600;
    display: flex;
    align-items: center;

    i {
      color: $primary;
      font-size: 32px;
      transition: 0.3s;
      line-height: 0;
      margin-right: 8px;
    }

    &:hover {
      color: $primary;
      i {
        color: lighten($primary, 10);
      }
    }
  }

  @media (min-width: 1024px) {
    background-attachment: fixed;
  }

  @media (max-width: 768px) {
    height: 100vh;
    h1 {
      font-size: 28px;
      line-height: 36px;
    }
    h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    .btn-get-started, .btn-watch-video {
      font-size: 13px;
    }
  }

  @media (max-height: 500px) {
    height: 120vh;
  }

}
